<div class="p-3 bg-white mat-elevation-z8">
  <div class="d-flex justify-content-start">
    <div class="flex-grow-1">
        <div class="form-group col-md-6 mt-3">
          <label for="subject">{{ "BLOG_VIDEOS.SUBJECT" | translate }}</label>
          <input type="text" class="form-control" placeholder="Subject" id="subject" [(ngModel)]="subject">
        </div> 

        <div class="form-group col-md-6 mt-3">
          <label for="hungerType">{{ "MOBILE_USERS.Hunger_Type" | translate }}</label>
          <select *ngIf="lang == 'en'" class="form-control" id="hungerType" [(ngModel)]="selectedHungerTypeId">
            <option *ngFor="let hungerType of hungerTypes" [value]="hungerType.id">{{ hungerType.name }}</option>
          </select>
          <select *ngIf="lang == 'ar'" class="form-control" id="hungerType" [(ngModel)]="selectedHungerTypeId">
            <option *ngFor="let hungerType of hungerTypes" [value]="hungerType.id">{{ hungerType.nameAr }}</option>
          </select>
        </div>

  <div class="card col-md-10 mt-4">
    <div class="card-header">{{ 'ATTACHMENTS.MAIN_VIDEO' | translate }}</div>
    <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="video/mp4,video/x-m4v,video/*" />
      </div>
      <div class="col-md-6">
        <div *ngIf="url">
          <video width="300" height="200" src="{{url}}" controls>
            Your browser does not support the video tag.
          </video>
      </div>
      </div>
    </div>
    </div> 
  </div>
  <div>
    <div class="container" style="margin-left:0">
  <div class="row">
    <div class="col-md-3" style="margin-left:0">
      <button *ngIf="id == ''"  (click)="uploadFile()" type="button" class="btn btn-outline-secondary"> {{ "SHARED.ADD" | translate }}</button>
    </div>
    <div class="col-md-3">
      <button *ngIf="id != ''" (click)="updateFile()" type="button" class="btn btn-outline-secondary">{{ "MOBILE_USERS.Save" | translate }}</button>
    </div>
    <div class="col-md-3" style="margin-left:0">
      <button *ngIf="id != ''" (click)="delete($event,id)" type="button" class="button-1">{{ "SHARED.DELETE" | translate }}</button>
    </div>   
  </div>
  </div>
  </div>
</div>
</div>
</div>



<!-- <div class="container">
  <div>
    <label for="subject">Subject:</label>
    <input type="text" id="subject" [(ngModel)]="subject" />
  </div>
  <div>
    <label for="hungerType">Hunger Type:</label>
    <select id="hungerType" [(ngModel)]="selectedHungerTypeId">
      <option *ngFor="let hungerType of hungerTypes" [value]="hungerType.id">{{ hungerType.name }}</option>
    </select>
  </div>
  <div>
    <label for="fileInput">Choose File:</label>
    <input type="file" id="fileInput" (change)="onFileSelected($event)" />
  </div>
    <div *ngIf="id != ''">
      <video width="300" height="200" src="{{selectedFile}}" controls>
        Your browser does not support the video tag.
      </video>
  </div>
  <button *ngIf="id == ''"  (click)="uploadFile()">Add</button>
  <button *ngIf="id != ''" (click)="updateFile()">Save changes</button>
  <button type="button" class="btn btn-danger" *ngIf="id != ''" (click)="delete($event,id)">Delete</button>
</div>
 -->
